// material-ui
import { Box, Typography } from "@mui/material";
import Attendant from "../../../../../menu-items/Attendant";
import Business from "../../../../../menu-items/Business";
import Owner from "../../../../../menu-items/Owner";
import { useState, useEffect } from "react";
// project import
import NavGroup from "./NavGroup";

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //
const getMenuItems = (role, businessId) => {
  let items = [];

  if (role === "ATTENDANT") {
    items = [Attendant];
  } else if (role === "BUSINESS_OWNER") {
    items = [
      {
        id: "Business",
        title: "",
        type: "group",
        children: [
          {
            id: "VisitorReport",
            title: "Visitor Report",
            type: "item",
            url: "/VisitorReport",
            // icon: icons.ProfileOutlined,
            // target: true
            breadcrumbs: false,
          },
          // {
          //     id: 'BusinessDashboard',
          //     title: 'Business Dashboard',
          //     type: 'item',
          //     url: '/BusinessDashboard',
          //     // icon: icons.LoginOutlined,
          //     // target: true
          //     breadcrumbs: false

          // },
          {
            id: "CreateAttendant",
            title: "Create Attendant",
            type: "item",
            url: "/CreateAttendant",
            // icon: icons.LoginOutlined,
            // target: true
            breadcrumbs: false,
          },
          {
            id: "ShowAttendants",
            title: "Show Attendants",
            type: "item",
            url: "/ShowAttendants",
            // icon: icons.ProfileOutlined,
            // target: true
            breadcrumbs: false,
          },
          {
            id: "CreateForm",
            title: "Create Form",
            type: "item",
            url: "/CreateForm",
            // icon: icons.ProfileOutlined,
            // target: true
            breadcrumbs: false,
          },
          //localdata && localdata.userId &&
          {
            id: "ShowForm",
            title: "Show Form",
            type: "item",
            url: "/form/" + businessId,
            breadcrumbs: false,
          },
        ].filter(Boolean),
      },
    ];
  } else if (role === "ADMIN") {
    items = [Owner];
  }

  return { items };
};

const Navigation = () => {
  const [menuItems, setMenuItems] = useState({ items: [] });
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const userdata = JSON.parse(localStorage.getItem("userdata"));
    if (userdata) {
      //setUserId(userdata.businessId? userdata.businessId : null);
      setMenuItems(
        getMenuItems(
          userdata.role,
          userdata.businessId ? userdata.businessId : null
        )
      );
    }
  }, []);

  const navGroups = menuItems.items.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
