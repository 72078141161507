import React, { useState, useEffect } from "react";
import "./RenderForm.css";
import axios from "axios";
import api from "../../api/index";
import { useNavigate } from "react-router-dom";

function RenderForm({ fields, initialValues }) {
  const [formData, setFormData] = useState(initialValues || {});
  const [dateData, setDateData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (initialValues) {
      // console.log('rendering initial values',initialValues);
      // console.log(formData);
      setFormData(initialValues);
      if (initialValues.hasOwnProperty("dob")) {
        const parts = initialValues.dob.split("-");

        const year = parts[0];
        const month = parts[1];
        const day = parts[2];

        // Create a new Date object in the desired format (mm/dd/yyyy)
        const newDate = `${day}/${month}/${year}`;
        console.log(newDate);
        setFormData((prevFormData) => {
          // Preserve the original casing of the field names
          return {
            ...prevFormData,
            dob: newDate,
          };
        });
        setDateData((prevDateData) => {
          return {
            ...prevDateData,
            dob: initialValues.dob,
          };
        });
      }
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setFormData((prevFormData) => {
      // Preserve the original casing of the field names
      return { ...prevFormData, [name]: value };
    });
  };
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    const currentDate = new Date(value);
    setFormData((prevFormData) => {
      // Preserve the original casing of the field names
      return {
        ...prevFormData,
        [name]: currentDate.toLocaleDateString("en-GB"),
      };
    });
    setDateData((prevDateData) => {
      return {
        ...prevDateData,
        [name]: value,
      };
    });
  };

  const renderFields = () => {
    return fields.map((field) => {
      const {
        fieldName,
        fieldType,
        fieldLabel,
        minLength,
        maxLength,
        regex,
        required,
        values,
      } = field;

      switch (fieldType) {
        case "text":
          return (
            <div className="fields" key={fieldName}>
              <label htmlFor={fieldName}>
                {fieldLabel}
                {required && <span>*</span>}:
              </label>
              <br />
              <input
                className="field"
                type="text"
                id={fieldName}
                name={fieldName}
                value={formData[fieldName.toLowerCase()] || ""}
                onChange={handleChange}
                minLength={minLength}
                maxLength={maxLength}
                required={required}
                pattern={regex ? regex : undefined}
              />
            </div>
          );

        case "radio":
          return (
            <div
              className="fields"
              style={{ display: "flex", flexDirection: "row" }}
              key={fieldName}
            >
              <label>
                {fieldLabel}
                {required && <span>*</span>}:{" "}
              </label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {values.map((option) => (
                  <label key={option} style={{ marginLeft: 10 }}>
                    <input
                      // className="field"
                      type="radio"
                      name={fieldName}
                      value={formData[fieldName.toLowerCase()] || option}
                      defaultValue={formData[fieldName.toLowerCase()]}
                      onChange={handleChange}
                      required={required}
                    />
                    {option}
                  </label>
                ))}
              </div>
            </div>
          );

        case "date":
          return (
            <div className="fields" key={fieldName}>
              <label htmlFor={fieldName}>
                {fieldLabel}
                {required && <span>*</span>}:
              </label>
              <br />
              <input
                className="field"
                type="date"
                id={fieldName}
                name={fieldName}
                value={
                  dateData[fieldName.toLowerCase()] ||
                  formData[fieldName.toLowerCase()]
                }
                onChange={handleDateChange}
                minLength={minLength}
                maxLength={maxLength}
                required={required}
                pattern={regex}
              />
            </div>
          );
        case "dropdown":
          return (
            <div className="fields" key={fieldName}>
              <label htmlFor={fieldName}>
                {fieldLabel}
                {required && <span>*</span>}:
              </label>
              <br />
              <select
                className="field"
                id={fieldName}
                name={fieldName}
                value={formData[fieldName.toLowerCase()] || ""}
                onChange={handleChange}
                required={required}
              >
                <option value="">Select {fieldLabel}</option>
                {values.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          );

        default:
          return null;
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const localdata = JSON.parse(localStorage.getItem("userdata"));
      // Create a new date object
      const currentDate = new Date().toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true, // Include AM/PM
      });

      const sendData = {
        businessId: localdata.businessId,
        attendantName: localdata.userName,
        attendantId: localdata.userId,
        formDataJson: JSON.stringify({
          ...formData,
          date: currentDate.toUpperCase(),
        }),
      };
      console.log("sending Data", sendData);
      const response = await axios.put(
        api + "api/attendants/formData/" + localdata.userId,
        sendData
      );
      alert("Form Saved!");
      navigate("/VisitorReport");
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("Error while submitting the form:", error);
      alert("Error while saving the form.");
    }
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        {renderFields()}
        <div style={{ display: "grid" }}>
          <button className="blueButton" type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default RenderForm;
