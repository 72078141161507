import { useEffect } from "react";
import "./thankyou.css";

const ThankYou = () => {
  // Prevent back navigation
  useEffect(() => {
    const preventBackNavigation = () => {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.go(1);
      };
    };

    preventBackNavigation();

    return () => {
      window.onpopstate = null; // Clean up the event listener when component unmounts
    };
  }, []);

  return (
    <div className="maincontainer">
      <div className="content">
        <div className="wrapper-1">
          <div className="wrapper-2">
            <h1>Thank you!</h1>
            <p>Your response has been recorded successfully. </p>
            {/* Avoid closing the tab - it may not work reliably */}
            {/* <button className="go-home" onClick={closeTab}>
            Close window
          </button> */}
          </div>
          <div className="footer-like">
            <p></p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ThankYou;
