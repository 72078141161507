import React, { useState, useEffect } from "react";
import "./SendLink.css";
import axios from "axios";
import api from "../../api/index";
import QRCode from "qrcode.react";

const ShareQR = () => {
  const localdata = JSON.parse(localStorage.getItem("userdata"));
  const [url, setUrl] = useState("");

  useEffect(() => {
    // const dataObject = {
    //   businessId: localdata.businessId,
    //   attendantName: localdata.userName,
    //   attendantId: localdata.userId,
    //   // other properties you want to include
    // };

    // // Convert the object to a JSON string
    // const jsonString = JSON.stringify(dataObject);

    // // Encode the JSON string to make it URL-safe
    // const encodedData = encodeURIComponent(jsonString);
    const apiUrl = api + "api/attendants/" + localdata.userId; // Replace with your API URL

    axios
      .get(apiUrl)
      .then((response) => {
        const attendantData = response.data.data;
        setUrl(`https://visitor-clip.com/QR/${attendantData.mobileNo}`);
        console.log(`https://visitor-clip.com/QR/${attendantData.mobileNo}`);
      })
      .catch((error) => {
        console.error("Error fetching form data:", error);
      });

    console.log(localdata);
  }, [localdata]);

  const downloadQR = () => {
    const canvas = document.getElementById("QR");
    const padding = 10; // Set your desired padding value

    // Create a new canvas with padding
    const paddedCanvas = document.createElement("canvas");
    const context = paddedCanvas.getContext("2d");
    paddedCanvas.width = canvas.width + 2 * padding;
    paddedCanvas.height = canvas.height + 2 * padding;

    // Draw the QR code onto the new canvas with padding
    context.fillStyle = "white"; // Set the background color if needed
    context.fillRect(0, 0, paddedCanvas.width, paddedCanvas.height);
    context.drawImage(canvas, padding, padding, canvas.width, canvas.height);

    // Convert the new canvas to an image for download
    const pngUrl = paddedCanvas
      .toDataURL("image/jpg")
      .replace("image/jpg", "image/octet-stream");

    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QR.jpg";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div
      className="center-box"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <QRCode
        id="QR"
        value={url}
        style={{ width: 200, height: 200, marginBottom: 10 }}
      />
      <button
        className="btn btn-success"
        onClick={downloadQR}
        style={{ fontSize: 14 }}
      >
        Download QR
      </button>
    </div>
  );
};

export default ShareQR;
