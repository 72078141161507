// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center-box {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; Adjust as needed */
}

.box {
  border: 1px solid #000;
  border-radius: 5px;
  padding: 20px;
  width: 300px; /* Adjust width as needed */
  text-align: center;
}
.field {
  width: 95%;
  padding: 8px;
  border: 1px solid gray;
  border-radius: 5px;
  margin: 10px;
}
.blueButton {
  align-self: center;
  padding: 10px;
  border: none;
  background-color: #21becc;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/attendant/SendLink/SendLink.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,oCAAoC;AACtC;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,YAAY,EAAE,2BAA2B;EACzC,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".center-box {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  /* height: 100vh; Adjust as needed */\n}\n\n.box {\n  border: 1px solid #000;\n  border-radius: 5px;\n  padding: 20px;\n  width: 300px; /* Adjust width as needed */\n  text-align: center;\n}\n.field {\n  width: 95%;\n  padding: 8px;\n  border: 1px solid gray;\n  border-radius: 5px;\n  margin: 10px;\n}\n.blueButton {\n  align-self: center;\n  padding: 10px;\n  border: none;\n  background-color: #21becc;\n  color: white;\n  font-weight: bold;\n  cursor: pointer;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
