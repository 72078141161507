import React, { useState, useEffect } from "react";
import "./RenderForm.css";
import axios from "axios";
import api from "../../api/index";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

function RenderForm({ fields, initialValues, userName, userId, businessId }) {
  const [formData, setFormData] = useState(initialValues || {});
  const [dateData, setDateData] = useState({});
  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);

  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  console.log("userName:", userName, "Id:", userId);

  useEffect(() => {
    if (initialValues) {
      // console.log('rendering initial values',initialValues);
      // console.log(formData);
      setFormData(initialValues);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setFormData((prevFormData) => {
      // Preserve the original casing of the field names
      return { ...prevFormData, [name]: value };
    });
  };
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    const currentDate = new Date(value);
    setFormData((prevFormData) => {
      // Preserve the original casing of the field names
      return {
        ...prevFormData,
        [name]: currentDate.toLocaleDateString("en-GB"),
      };
    });
    setDateData((prevDateData) => {
      return {
        ...prevDateData,
        [name]: value,
      };
    });
  };

  const renderFields = () => {
    return fields.map((field) => {
      const {
        fieldName,
        fieldType,
        fieldLabel,
        minLength,
        maxLength,
        regex,
        required,
        values,
      } = field;

      switch (fieldType) {
        case "text":
          return (
            <div className="fields" key={fieldName}>
              <label htmlFor={fieldName}>
                {fieldLabel}
                {required && <span>*</span>}:
              </label>
              <br />
              <input
                className="field"
                type="text"
                id={fieldName}
                name={fieldName}
                value={formData[fieldName.toLowerCase()] || ""}
                onChange={handleChange}
                minLength={minLength}
                maxLength={maxLength}
                required={required}
                pattern={regex ? regex : undefined}
              />
            </div>
          );

        case "radio":
          return (
            <div
              className="fields"
              style={{ display: "flex", flexDirection: "row" }}
              key={fieldName}
            >
              <label>
                {fieldLabel}
                {required && <span>*</span>}:{" "}
              </label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {values.map((option) => (
                  <label key={option} style={{ marginLeft: 10 }}>
                    <input
                      // className="field"
                      type="radio"
                      name={fieldName}
                      value={formData[fieldName.toLowerCase()] || option}
                      defaultValue={formData[fieldName.toLowerCase()]}
                      onChange={handleChange}
                      required={required}
                    />
                    {option}
                  </label>
                ))}
              </div>
            </div>
          );

        case "date":
          return (
            <div className="fields" key={fieldName}>
              <label htmlFor={fieldName}>
                {fieldLabel}
                {required && <span>*</span>}:
              </label>
              <br />
              <input
                className="field"
                type="date"
                id={fieldName}
                name={fieldName}
                value={dateData[fieldName.toLowerCase()] || ""}
                onChange={handleDateChange}
                minLength={minLength}
                maxLength={maxLength}
                required={required}
                pattern={regex}
              />
            </div>
          );
        case "dropdown":
          return (
            <div className="fields" key={fieldName}>
              <label htmlFor={fieldName}>
                {fieldLabel}
                {required && <span>*</span>}:
              </label>
              <br />
              <select
                className="field"
                id={fieldName}
                name={fieldName}
                value={formData[fieldName.toLowerCase()] || ""}
                onChange={handleChange}
                required={required}
              >
                <option value="">Select {fieldLabel}</option>
                {values.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          );

        default:
          return null;
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setOpen(true);
      // Create a new date object
      const currentDate = new Date().toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true, // Include AM/PM
      });

      const sendData = {
        businessId: businessId,
        attendantName: userName,
        attendantId: userId,
        formDataJson: JSON.stringify({
          ...formData,
          date: currentDate.toUpperCase(),
        }),
      };
      console.log("sending Data", sendData);
      const response = await axios.put(
        api + "api/attendants/formData/" + userId,
        sendData
      );
      if (response) setOpen(false);
      navigate("/thankYou");

      // setMessage("Thank You! Your Form Saved Successfully.");
      // setShowAlert(true);
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("Error while submitting the form:", error);
      setMessage("Error while saving the form.");
      setShowError(true);
    }
  };

  const handleClose = () => {
    setShowAlert(false);
    setOpen(false);
    window.location.reload();
  };
  const handleCloseError = () => {
    setShowError(false);
    setOpen(false);
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        {renderFields()}
        <div style={{ display: "grid" }}>
          <button className="blueButton" type="submit">
            Submit
          </button>
        </div>
      </form>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={showAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message !== "" && <Alert severity="success">{message}</Alert>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Done</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message !== "" && <Alert severity="error">{message}</Alert>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseError}>Done</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RenderForm;
