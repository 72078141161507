// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding: 3%;
  border: 1px solid;
  border-radius: 5px;
}
.field {
  width: 95%;
  padding: 8px;
  border: 1px solid gray;
  border-radius: 5px;
}
.fields {
  margin-bottom: 10px;
}
.blueButton {
  width: 200px;
  align-self: center;
  padding: 10px;
  border: none;
  background-color: #21becc;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/attendant/FormForQRScan/RenderForm.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;AACpB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".container {\n  padding: 3%;\n  border: 1px solid;\n  border-radius: 5px;\n}\n.field {\n  width: 95%;\n  padding: 8px;\n  border: 1px solid gray;\n  border-radius: 5px;\n}\n.fields {\n  margin-bottom: 10px;\n}\n.blueButton {\n  width: 200px;\n  align-self: center;\n  padding: 10px;\n  border: none;\n  background-color: #21becc;\n  color: white;\n  font-weight: bold;\n  cursor: pointer;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
