import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../api/index";
import RenderForm from "../RenderForm/renderForm.js";
import { useParams } from "react-router-dom";

const Form = () => {
  const [formData, setFormData] = useState(null);
  const [formError, setFormError] = useState(false);
  const { id } = useParams();
  const [business, setBusiness] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [logo, setLogo] = useState();
  useEffect(() => {
    // Replace with the actual URL of your API endpoint
    const apiUrl = api + "api/businesses/form/" + id; // Replace with your API URL
    let isMounted = true; // Flag to check if component is mounted

    axios
      .get(apiUrl)
      .then((response) => {
        if (isMounted) {
          // Check if component is still mounted before updating state
          const data = response.data;
          if (data.statusCode === 200 && data.data) {
            console.log(data);
            const formJson = JSON.parse(data.data.formJson);
            data.data.businessName && setBusiness(data.data.businessName);
            data.data.formDescription &&
              setFormDescription(data.data.formDescription);
            setFormData(formJson);
            setLogo(data.data.logo);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching form data:", error);
        setFormError(true);
      });

    // Cleanup function to handle component unmounting
    return () => {
      isMounted = false;
    };
  }, [id]);

  console.log(formData);
  return (
    <div
      style={{
        width: "100%",
        padding: 15,
      }}
    >
      {formData ? (
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginBottom: 5,
            }}
          >
            {logo && (
              <div
                style={{
                  width: 75,
                  //display: "flex",
                  //justifyContent: "flex-start",
                }}
              >
                <img
                  style={{
                    height: 75,
                    //marginRight: 10,
                    width: 75,
                    //objectFit: "contain",
                  }}
                  alt="Logo"
                  src={`data:image/png;base64,${logo}`}
                />
              </div>
            )}
            <div
              style={{
                width: logo ? "calc(100% - 75px)" : "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: 100,
              }}
            >
              <p style={{ margin: 0, fontSize: 24, fontWeight: 600 }}>
                {business}
              </p>
              <p style={{ margin: 0, fontSize: 20, fontWeight: 600 }}>
                {formDescription}
              </p>
            </div>
          </div>
          <RenderForm fields={formData} />
        </div>
      ) : formError ? (
        <div>Form not created. Please create it first.</div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default Form;
